<template>
  <el-container>
    <el-main class="data">
      <div class="title">
        <span></span>
        数据概况
      </div>
      <ul>
        <li>
          <img src="../../assets/image/portrait-icon1.png" alt="" />
          <div class="total_num">
            <p>用户总数</p>
            <p>{{ info.total }}</p>
          </div>
        </li>
        <li>
          <img src="../../assets/image/portrait-icon2.png" alt="" />
          <div class="total_num">
            <p>昨日新增用户</p>
            <p>{{ info.yesterday }}</p>
          </div>
        </li>
        <li>
          <img src="../../assets/image/portrait-icon3.png" alt="" />
          <div class="total_num">
            <p>过去七天新增用户</p>
            <p>{{ info.week }}</p>
          </div>
        </li>
        <li>
          <img src="../../assets/image/portrait-icon4.png" alt="" />
          <div class="total_num">
            <p>会员人数</p>
            <p>{{ info.cardtotal }}</p>
          </div>
        </li>
        <li>
          <img src="../../assets/image/portrait-icon5.png" alt="" />
          <div class="total_num">
            <p>昨日新增会员</p>
            <p>{{ info.cardyesterday }}</p>
          </div>
        </li>
        <li>
          <img src="../../assets/image/portrait-icon.png" alt="" />
          <div class="total_num">
            <p>过去七天新增会员</p>
            <p>{{ info.cardweek }}</p>
          </div>
        </li>
      </ul>
    </el-main>
    <div class="region-sex">
      <el-main>
        <div class="title">
          <span></span>
          用户地域分布
        </div>
        <div class="region-list">
          <table>
            <tr v-for="(item, index) in info.area" :key="index">
              <td>{{ item.province }}</td>
              <td>
                <div class="rate-box">
                  <div class="rate" :style="{ width: item.num > 0 ? (item.num / info.areaMax) * 100 + '%' : '' }"></div>
                </div>
              </td>
              <td>{{ item.num }}</td>
            </tr>
          </table>
        </div>
      </el-main>
      <el-main>
        <div class="title">
          <span></span>
          用户性别比例
        </div>
        <div id="sex"></div>
      </el-main>
    </div>
    <div class="user-register">
      <el-main>
        <div class="title">
          <span></span>
          用户生命周期
        </div>
        <div id="user"></div>
      </el-main>
      <el-main>
        <div class="title">
          <span></span>
          用户注册渠道分布
        </div>
        <div id="register"></div>
      </el-main>
    </div>
    <div class="consumption_num-consumption_money">
      <el-main>
        <div class="title">
          <span></span>
          用户消费频次(人)
        </div>
        <div id="frequency"></div>
      </el-main>
      <el-main>
        <div class="title">
          <span></span>
          用户金额分布(人)
        </div>
        <div id="consume"></div>
      </el-main>
    </div>
  </el-container>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      sex_n: '',
      sex_v: '',
      sex_w: '',
      info: {},
      area_option: {
        tooltip: {
          tooltip: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: !0,
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: !0,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: '20%',
            data: [],
          },
        ],
      },
      user_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter(param) {
            let describe = ''
            if(param[0].name == '活跃期') describe = '三个月内下过单'
            if(param[0].name == '沉默期') describe = '六个月内下过单，三个月内没下单'
            if(param[0].name == '睡眠期') describe = '一年内下过单，六个月内没下单'
            if(param[0].name == '流失期') describe = '一年内没下过单'
            return `${param[0].marker}${param[0].name}：${param[0].value}<br/>${describe}`;
          },
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        grid: {
          top: '8%',
          left: '0%',
          right: '2%',
          bottom: '0%',
          containLabel: true,
        },
        series: [
          {
            label: {
              normal: {
                show: true,
                position: 'top', //显示文字位置
                formatter(param) {
                  let value = '';
                  if (param.value > 0) {
                    value = `${param.name}：${param.value}`;
                  }
                  return '{a| ' + value + '}';
                },
                rich: {
                  a: {
                    color: '#17171A',
                  },
                },
              },
            },
            itemStyle: {
              normal: {
                color(val) {
                  let colorList = [
                    ['#ffd3e5', '#ffe8f1'],
                    ['#feebb4', '#fef4d7'],
                    ['#c2e5cb', '#f7fcf8'],
                    ['#b8d1ff', '#f6f9ff'],
                  ];
                  let colorItem = colorList[val.dataIndex];
                  return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: colorItem[0],
                      },
                      {
                        offset: 1,
                        color: colorItem[1],
                      },
                    ],
                    false
                  );
                },
              },
            },
            barWidth: 100,
            data: [],
            type: 'bar',
          },
        ],
      },
      register_option: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            console.log(params);
            if (params.percent) {
              return `${params.name} : ${params.data.num}`;
            } else {
              return '';
            }
          },
        },
        legend: {
          orient: 'vertical',
          bottom: 'center',
          right: 50,
          data: [],
          show: true,
          textStyle: {
            rich: {
              a: {
                fontWeight: 700,
                marginLeft: 20,
              },
            },
          },
        },
        color: ['#5654F3', '#4DA9FE', '#50C27B', '#FDA13B', '#FD5E94', '#5B5B66'],
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            selectedMode: 'single',
            radius: ['50%', '65%'],
            data: [],
            label: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
      frequency_option: {
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        grid: {
          top: '8%',
          left: '0%',
          right: '2%',
          bottom: '0%',
          containLabel: true,
        },
        color: '#1467FF',
        series: [
          {
            label: {
              normal: {
                show: true,
                position: 'top', //显示文字位置
                formatter(param) {
                  let value = '';
                  if (param.value > 0) {
                    value = `${param.value}`;
                  }
                  return '{a| ' + value + '}';
                },
                rich: {
                  a: {
                    color: '#17171A',
                  },
                },
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [16, 16, 0, 0],
              },
            },
            barWidth: 32, //柱形宽度
            data: [],
            type: 'bar',
          },
        ],
      },
      consume_option: {
        tooltip: {
          trigger: 'axis',
          type: 'line',
          formatter: function (param) {
            return `${param[0].marker}${param[0].name}：${param[0].value}`;
          },
          axisPointer: {
            type: 'cross',
            lineStyle: {
              type: 'solid',
            },
          },
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        grid: {
          top: '8%',
          left: '0%',
          right: '2%',
          bottom: '0%',
          containLabel: true,
        },
        color: '#1467FF',
        series: [
          {
            label: {
              normal: {
                show: true,
                position: 'top', //显示文字位置
                formatter(param) {
                  let value = `${param.value}`;
                  return '{a| ' + value + '}';
                },
                rich: {
                  a: {
                    color: '#17171A',
                  },
                },
              },
            },
            itemStyle: {
              normal: {},
            },
            lineStyle: {
              normal: {
                shadowColor: 'rgba(23, 23, 26, 0.1)',
                shadowOffsetY: 16,
                shadowOffsetX: 2,
              },
            },
            showSymbol: false,
            data: [],
            type: 'line',
          },
        ],
      },
      sex_options: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            console.log(params);
            if (params.percent) {
              return `${params.name} : ${params.data.num}`;
            } else {
              return '';
            }
          },
        },
        legend: {
          orient: 'vertical',
          bottom: 'center',
          right: 50,
          data: [],
          show: true,
          textStyle: {
            rich: {
              a: {
                fontWeight: 700,
                marginLeft: 20,
              },
            },
          },
        },
        graphic: {
          //图形中间文字
          type: 'text',
          left: 'center',
          top: 'center',
          // top: 'center',
          style: {
            text: '',
            textAlign: 'center',
            fill: '#17171A',
            fontSize: 14,
          },
        },
        color: ['#FBBC05', '#FE6DA9', '#1467FF'],
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            selectedMode: 'single',
            radius: ['50%', '65%'],
            data: [],
            label: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
    };
  },
  created() {
    this.getPortrait();
  },
  methods: {
    max(arr) {
      let max = arr[0].num || 0;
      let len = arr.length;
      for (let i = 0; i < len; i++) {
        if (arr[i].num > max) {
          max = arr[i].num;
        }
      }
      return max;
    },
    getPortrait() {
      this.$axios.post(this.$api.user.getPortrait).then(res => {
        if (res.code == 0) {
          let data = res.result;
          let sexDom = document.getElementById('sex');
          let userDom = document.getElementById('user');
          let registerDom = document.getElementById('register');
          let frequencyDom = document.getElementById('frequency');
          let consumeDom = document.getElementById('consume');
          let consumeChart = echarts.init(consumeDom);
          let frequencyChart = echarts.init(frequencyDom);
          let registerChart = echarts.init(registerDom);
          let userChart = echarts.init(userDom);
          let sexChart = echarts.init(sexDom);
          let sex_options = this.sex_options;
          let user_option = this.user_option;
          let register_option = this.register_option;
          let frequency_option = this.frequency_option;
          let consume_option = this.consume_option;
          let total_num = 0;
          if (data.area) {
            data.areaMax = this.max(data.area);
            data.area.sort(function (a, b) {
              return b.num - a.num;
            });
          } else {
            data.area = [
              {
                province: '广东省',
                num: 0,
              },
              {
                province: '湖南省',
                num: 0,
              },
              {
                province: '湖北省',
                num: 0,
              },
              {
                province: '北京市',
                num: 0,
              },
              {
                province: '河北省',
                num: 0,
              },
              {
                province: '山西省',
                num: 0,
              },
              {
                province: '山东省',
                num: 0,
              },
              {
                province: '广西省',
                num: 0,
              },
              {
                province: '其他区域',
                num: 0,
              },
            ];
          }
          if (data.sex.length) {
            data.sex.map(item => {
              sex_options.series[0].data.push({
                value: item.percentage,
                name: item.sex_t,
                num: item.num,
              });
              sex_options.legend.data.push(item.sex_t);
              total_num = total_num + item.num;
            });
          } else {
            //假数据
            sex_options.series[0].data.push(
              {
                value: 0,
                name: '男性',
                num: 0,
              },
              {
                value: 0,
                name: '女性',
                num: 0,
              },
              {
                value: 0,
                name: '未知',
                num: 0,
              }
            );
            sex_options.legend.data.push('男性', '女性', '未知');
          }
          sex_options.legend.formatter = function (val) {
            let arr = null;
            if (data.sex.length) {
              data.sex.map(item => {
                if (item.sex_t == val) {
                  let percentage = item.percentage + '%';
                  arr = [val + '：', '{a| ' + percentage + '}'];
                }
              });
            } else {
              arr = [val + '：', '{a| ' + '0%' + '}'];
            }
            return arr.join('');
          };
          sex_options.graphic.style.text = ['用户总数', total_num].join('\n\n');
          data.life.map(item => {
            user_option.xAxis.data.push(item.name);
            user_option.series[0].data.push(item.num);
          });
          let comfrom_total = 0;
          data.comfrom.map(item => {
            comfrom_total = comfrom_total + item.num;
          });
          if (data.comfrom.length) {
            data.comfrom.map(item => {
              let rate = ((item.num / comfrom_total) * 100).toFixed(2);
              item.rate = rate;
              item.comefrom_t = item.comefrom_t || '-';
              register_option.series[0].data.push({
                value: rate,
                name: item.comefrom_t,
                num: item.num,
              });
              register_option.legend.data.push(item.comefrom_t);
            });
            if (register_option.series[0].data.every(item => item.value > 10)) register_option.series[0].roseType = 'area';
          } else {
            //假数据
            register_option.series[0].data.push(
              {
                value: 0,
                name: '百度小程序',
                num: 0,
              },
              {
                value: 0,
                name: '支付宝小程序',
                num: 0,
              },
              {
                value: 0,
                name: '微信小程序',
                num: 0,
              },
              {
                value: 0,
                name: 'QQ小程序',
                num: 0,
              },
              {
                value: 0,
                name: '快应用',
                num: 0,
              },
              {
                value: 0,
                name: '抖音小程序',
                num: 0,
              }
            );
            register_option.legend.data.push('百度小程序', '支付宝小程序', '微信小程序', 'QQ小程序', '快应用', '抖音小程序');
          }
          register_option.legend.formatter = function (val) {
            let arr = null;
            if (data.comfrom.length) {
              data.comfrom.map(item => {
                if (item.comefrom_t == val) {
                  let rate = item.rate + '%';
                  arr = [val + '：', '{a| ' + rate + '}'];
                }
              });
            } else {
              arr = [val + '：', '{a| ' + '0%' + '}'];
            }
            return arr.join('');
          };
          data.frequency.map(item => {
            frequency_option.xAxis.data.push(item.name);
            frequency_option.series[0].data.push(item.num);
          });
          data.consume.map(item => {
            consume_option.xAxis.data.push(item.name);
            consume_option.series[0].data.push(item.num);
          });
          sexChart.setOption(sex_options);
          userChart.setOption(user_option);
          registerChart.setOption(register_option);
          frequencyChart.setOption(frequency_option);
          consumeChart.setOption(consume_option);
          this.info = data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  color: #17171a;
  flex-direction: column;
  font-size: 14px;
  .title {
    display: flex;
    align-items: center;
    span {
      width: 4px;
      height: 16px;
      background: #1467ff;
      margin-right: 8px;
    }
  }
  .el-main {
    background: #fff;
  }
  .data {
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      li {
        flex: 1;
        margin-right: 16px;
        height: 108px;
        border: 1px solid #f2f3f5;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 20px;
        }
        .total_num {
          P:last-child {
            font-size: 22px;
            font-weight: bold;
            margin-top: 10px;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .region-sex {
    margin-top: 10px;
    display: flex;
    height: 470px;
    .el-main {
      flex: 1;
      &:nth-child(1) {
        margin-right: 8px;
        .region-list {
          margin-top: 20px;
          table {
            tr td {
              padding: 10px;
              &:nth-child(2) {
                .rate-box {
                  width: 480px;
                  height: 16px;
                  background: #f8f9fa;
                  display: flex;
                }
                .rate {
                  height: 16px;
                  height: 100%;
                  background: #1467ff;
                  border-radius: 0px 8px 8px 0px;
                }
              }
              &:last-child {
                font-weight: bold;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        #sex {
          flex: 1;
        }
      }
    }
  }
  .user-register {
    margin-top: 10px;
    display: flex;
    height: 470px;
    .el-main {
      flex: 1;
      &:nth-child(1) {
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        #user {
          flex: 1;
        }
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        #register {
          flex: 1;
        }
      }
    }
  }
  .consumption_num-consumption_money {
    margin-top: 10px;
    display: flex;
    height: 470px;
    .el-main {
      flex: 1;
      &:nth-child(1) {
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        #frequency {
          flex: 1;
        }
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        #consume {
          flex: 1;
        }
      }
    }
  }
}
</style>
